import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { orderPropType } from '~/propTypes';

import OrderInformation from './OrderInformation';
import OrderLineItems from './OrderLineItems';
import OrderPayments from './OrderPayments';
import OrderRefunds from './OrderRefunds';
import OrderTransactions from './OrderTransactions';

export default function OrderDetail({ data }) {
  const order = data.getOrder;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <OrderInformation order={order} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrderLineItems order={order} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrderTransactions order={order} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrderPayments order={order} />
      </Grid>
      {order.refunds && order.refunds.length > 0 && (
        <Grid item xs={12} md={12}>
          <OrderRefunds order={order} />
        </Grid>
      )}
    </Grid>
  );
}

OrderDetail.propTypes = {
  data: PropTypes.shape({
    getOrder: orderPropType.isRequired,
  }),
};
