import { Box } from '@material-ui/core';
import { paymentMethods, sites } from 'trhub-utils';

import SelectField from '~/components/Select';
import useQueryParams from '~/hooks/useQueryParams';

import CanceledSubscriptionsListResult from './CanceledSubscriptionsListResult';

const initialValues = {
  paymentMethod: '',
  site: '',
};

export default function CanceledSubscriptionsList() {
  const { params, state, handleChange } = useQueryParams(initialValues);

  return (
    <Box>
      <Box
        maxWidth="100%"
        display="flex"
        flexWrap="wrap"
        gridAutoFlow="column"
        gridColumnGap="10px"
        gridRowGap="10px"
        alignItems="center"
        mb={2}
        css={{
          '.MuiFormControl-root': {
            minWidth: '120px',
          },
        }}
      >
        <SelectField
          label="Betalmetod"
          value={state.paymentMethod}
          onChange={e => handleChange('paymentMethod', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            ...Object.entries(paymentMethods).map(([value, label]) => ({
              label,
              value,
            })),
          ]}
        />
        <SelectField
          label="Sajt"
          value={state.site}
          onChange={e => handleChange('site', e.target.value)}
          options={[
            { value: 'all', label: 'Alla' },
            ...Object.entries(sites).map(([value, label]) => ({
              value,
              label,
            })),
          ]}
        />
      </Box>
      <CanceledSubscriptionsListResult params={params} />
    </Box>
  );
}
