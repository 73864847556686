import { MenuItem, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const Select = ({ options, ...props }) => (
  <TextField variant="outlined" select {...props}>
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        <em>{option.label}</em>
      </MenuItem>
    ))}
  </TextField>
);

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

export default Select;
