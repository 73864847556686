import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { subscriptionSites } from 'trhub-utils';

import useQueryParams from '~/hooks/useQueryParams';
import toOptions from '~/utils/toOptions';

import SelectField from '../Select';
import query from './_GetReportProducts.gql';

const useStyles = makeStyles(() => ({
  productTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
}));
const initialValues = {
  productIds: [],
  site: '',
};

export default function ProductSelect() {
  const { state, handleChange, handleChanges } = useQueryParams(initialValues);
  const classes = useStyles();
  const { error, data } = useQuery(query, {
    variables: { site: state.site },
  });
  const siteOptions = toOptions(subscriptionSites);
  let options = data?.getReportProducts;
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!options) {
    return <CircularProgress />;
  }
  if (state.site === 'travronden') {
    options = [
      { id: 'allPrint', name: '- Markera alla printprodukter' },
      { id: 'allDigital', name: '- Markera alla digitala produkter' },
      ...options,
    ];
  }

  return (
    <>
      <SelectField
        className={classes.productTextField}
        label="Varumärke"
        variant="outlined"
        select
        value={state.site}
        onChange={e => {
          handleChanges({ productIds: [], site: e.target.value });
        }}
        options={[
          ...(siteOptions
            ? siteOptions.map(item => ({
                value: item.value,
                label: item.text,
              }))
            : []),
        ]}
      />
      <SelectField
        label="Produkt"
        value={state.productIds}
        onChange={e => {
          if (e.target.value.includes('allPrint')) {
            handleChange(
              'productIds',
              options.filter(x => x.name.includes('Print')).map(y => y.id),
            );
          } else if (e.target.value.includes('allDigital')) {
            handleChange(
              'productIds',
              options
                .filter(
                  x =>
                    !x.name.includes('Print') &&
                    !['allPrint', 'allDigital'].includes(x.id),
                )
                .map(y => y.id),
            );
          } else {
            handleChange('productIds', e.target.value);
          }
        }}
        SelectProps={{
          multiple: true,
          renderValue: data => (
            <div>
              {options
                .filter(x => data.includes(x.id))
                .map(y => y.name)
                .join(', ')}
            </div>
          ),
        }}
        className={classes.productTextField}
        options={[
          ...(options
            ? options.map(item => ({
                value: item.id,
                label: item.name,
              }))
            : []),
        ]}
      />
    </>
  );
}
