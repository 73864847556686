import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { issueCategories, issueSeverities, issueStatus } from 'trhub-utils';

import shallowEqual from '~/utils/shallowEqual';
import toOptions from '~/utils/toOptions';

import useQueryParams from '../../hooks/useQueryParams';
import IssueListResult from './IssueListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

const initialValues = {
  searchValues: '',
  severity: '',
  category: '',
  assignee: '',
  status: 'NEW',
};

export default function IssueList() {
  const classes = useStyles();

  const { pathname } = useLocation();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);

  const options = {
    categories: toOptions(issueCategories),
    severities: toOptions(issueSeverities),
    status: toOptions(issueStatus),
  };

  return (
    <>
      <Box mb={2} display="flex">
        <Box
          maxWidth="100%"
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Sök"
            variant="outlined"
            value={state.searchValues}
            onChange={e => handleChange('searchValues', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Allvarsgrad"
            variant="outlined"
            select
            value={state.severity}
            onChange={e => handleChange('severity', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.severities.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Kategori"
            variant="outlined"
            select
            value={state.category}
            onChange={e => handleChange('category', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.categories.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Mottagare"
            variant="outlined"
            select
            value={state.assignee}
            onChange={e => handleChange('assignee', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
          </TextField>
          <TextField
            className={classes.textField}
            label="Status"
            variant="outlined"
            select
            value={state.status}
            onChange={e => handleChange('status', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.status.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              css={() => ({
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        <Box minWidth="30%" textAlign="right">
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        </Box>
      </Box>
      <Box>
        <IssueListResult params={params} options={options} />
      </Box>
    </>
  );
}
