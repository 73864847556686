import { useQuery } from '@apollo/client';
import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';

import query from './_GetBettipStats.gql';

export default function BettipStatsListResults({ params }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);

  const { error, data } = useQuery(query, {
    variables: {
      site: params.site,
      betType: params.betType,
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getBetTipStatistics;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }
  const headers = [
    'Speltips',
    'Pris',
    'Totalt',
    'Ordinarie pris',
    'Kampanj',
    'Klippkort',
    'Gratis',
    'Nettoinkomst',
  ];
  const helpTexts = [
    null,
    'Ordinarie styckpris',
    'Totalt antal sålda tips',
    'Antal sålda tips till ordinarie pris',
    'Antal sålda tips med kampanj',
    'Antal tips utnyttjade på klippkort',
    'Antal tips som givits ut gratis',
    'Nettoinkomst på sålda speltips, exklusive klippkortsutnyttjande',
  ];

  return (
    <>
      <TableList
        page={page}
        pageSize={pageSize}
        data={res}
        headers={headers}
        helpTexts={helpTexts}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
      >
        {res?.products.length > 0 ? (
          res.products.map((product, index) => (
            <TableRow hover key={index}>
              <TableCell>{product.name}</TableCell>
              <TableCell>{formatMoney(product.price)}</TableCell>
              <TableCell>{product.sales.total}</TableCell>
              <TableCell>{product.sales.regular}</TableCell>
              <TableCell>{product.sales.campaign}</TableCell>
              <TableCell>{product.sales.ticket}</TableCell>
              <TableCell>{product.sales.free}</TableCell>
              <TableCell>{formatMoney(product.sales.revenue)}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={10} align="center">
              Ingen speltipsstatistik finns för denna filtrering. Prova att ange
              en annan sajt, eller en annan spelform.
            </TableCell>
          </TableRow>
        )}
      </TableList>
    </>
  );
}

BettipStatsListResults.propTypes = {
  params: PropTypes.shape({
    site: PropTypes.string,
    betType: PropTypes.string,
  }).isRequired,
};
