import { useQuery } from '@apollo/client';
import {
  Button,
  Chip,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { userWatchTypes, watcher } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TablePaper from '~/components/TablePaper';
import { userPropType } from '~/propTypes';

import query from './_GetUserWatchings.gql';

const useStyles = makeStyles(theme => ({
  chipList: {
    display: 'flex',
    justifyContent: 'right',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

function TabPanel({ value, tabKey, data, index, watchings, ...other }) {
  const classes = useStyles();
  const userWatchType = tabKey === 'meets' ? 'meet' : tabKey;

  // Show max 5 watchings;
  const shownWatchings = watchings[userWatchType]
    ? watchings[userWatchType].slice(0, 5)
    : [];

  let watchingsLabel = '';
  switch (userWatchType) {
    case 'horse':
      watchingsLabel = 'Bevakade hästar';
      break;
    case 'author':
      watchingsLabel = 'Bevakade skribenter';
      break;
    case 'topic':
      watchingsLabel = 'Bevakade ämnen';
      break;
    case 'meet':
      watchingsLabel = 'Bevakade tävlingsdagar';
      break;
    default:
      watchingsLabel = '';
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Table>
          <TableBody>
            <TableRow key={'methods'}>
              <TableCell component="th" scope="row">
                Hur vill du bli meddelad?
              </TableCell>
              <TableCell align="right">
                <div className={classes.chipList}>
                  {data.methods.map(key => (
                    <Chip key={key} label={watcher.methods[key]} />
                  ))}
                </div>
              </TableCell>
            </TableRow>
            {data.when && (
              <TableRow key={'when'}>
                <TableCell component="th" scope="row">
                  När vill du bli meddelad?
                </TableCell>
                <TableCell align="right">
                  <div className={classes.chipList}>
                    {data.when.map(key => (
                      <Chip key={key} label={watcher.when[key]} />
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.sorting && (
              <>
                <TableRow key={'sorting-start'}>
                  <TableCell component="th" scope="row">
                    Sortering av startanmälda hästar
                  </TableCell>
                  <TableCell align="right">
                    {data.sorting.start && (
                      <Chip
                        key={data.sorting.start}
                        label={watcher.sort[data.sorting.start]}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={'sorting-watch'}>
                  <TableCell component="th" scope="row">
                    Sortering av bevakade hästar
                  </TableCell>
                  <TableCell align="right">
                    {data.sorting.watch && (
                      <Chip
                        key={data.sorting.watch}
                        label={watcher.sort[data.sorting.watch]}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
            {data.betTypes && (
              <TableRow key={'betTypes'}>
                <TableCell component="th" scope="row">
                  Speltyper
                </TableCell>
                <TableCell align="right">
                  <div className={classes.chipList}>
                    {data.betTypes.map(key => (
                      <Chip key={key} label={key} />
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {Object.keys(userWatchTypes).includes(userWatchType) && (
              <TableRow key={'betTypes'}>
                <TableCell component="th" scope="row">
                  {watchingsLabel}
                </TableCell>
                <TableCell align="right">
                  <div className={classes.chipList}>
                    {shownWatchings.map(({ refId, label }) => (
                      <Chip key={refId} label={label} />
                    ))}
                    {watchings[userWatchType].length >
                      shownWatchings.length && (
                      <Chip
                        key="more"
                        label={`+ ${
                          watchings[userWatchType].length -
                          shownWatchings.length
                        } till`}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  tabKey: PropTypes.string.isRequired,
  watchings: PropTypes.any.isRequired,
};

export default function WatcherSettings({ userData }) {
  const [tab, setTab] = useState(0);
  const { pathname } = useLocation();
  const {
    error,
    loading,
    data: userWatchings,
  } = useQuery(query, {
    variables: { userId: userData.id },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <TablePaper
      title="Bevakningsinställningar"
      button={
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          to={`${pathname}/watchersettings/edit`}
        >
          Redigera
        </Button>
      }
    >
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Hästar" />
        <Tab label="Spelform" />
        <Tab label="Jackpot" />
        <Tab label="Nyheter" />
        <Tab label="Tävlingsdagar" />
        <Tab label="Ämnen" />
        <Tab label="Skribenter" />
      </Tabs>
      {['horse', 'betType', 'jackpot', 'news', 'meets', 'topic', 'author'].map(
        (key, index) => (
          <TabPanel
            key={key}
            value={tab}
            index={index}
            data={userData.watcherSettings[key]}
            watchings={userWatchings.getUserWatchings}
            tabKey={key}
          />
        ),
      )}
    </TablePaper>
  );
}

WatcherSettings.propTypes = {
  userData: userPropType.isRequired,
};
