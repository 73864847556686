import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import CanceledSubscriptions from './CanceledSubscriptions';
import CardPayments from './CardPayments';
import Complaints from './Complaints';
import Debt from './Debt';
import Deviations from './Deviations';
import EconomyExport from './EconomyExport';
import EconomySales from './EconomySales';
import KeyMetrics from './KeyMetrics';
import Moss from './Moss';
import Sales from './Sales';
import UnsubscribeReasons from './UnsubscribeReasons';

export default function Reports() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Rapporter" />
      <Switch>
        <ErrorBoundRoute path={`${path}/economy_sales`}>
          <EconomySales />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/economy_export`}>
          <EconomyExport />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/sales`}>
          <Sales />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/moss`}>
          <Moss />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/deviations`}>
          <Deviations />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/debt`}>
          <Debt />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/keymetrics`}>
          <KeyMetrics />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/unsubscribe-reasons`}>
          <UnsubscribeReasons />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/complaints`}>
          <Complaints />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/card-payments`}>
          <CardPayments />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/canceled-subscriptions`}>
          <CanceledSubscriptions />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={path} exact>
          <Redirect to={`${path}/sales`} />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
