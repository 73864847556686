import { Link } from '@material-ui/core';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { productTypes, sites } from 'trhub-utils';

import DataGrid from '~/components/DataGrid';
import query from '~/utils/_GetProducts.gql';

const columns = [
  {
    field: 'name',
    flex: 3,
    headerName: 'Produkt',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/products/${params.row.id}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'type',
    flex: 1,
    headerName: 'Produkttyp',
    sortable: false,
    valueGetter: params => productTypes[params.value],
  },
  {
    field: 'sku',
    flex: 2,
    headerName: 'SKU',
  },
  {
    field: 'price',
    flex: 1,
    headerName: 'Pris',
    valueFormatter: params => formatMoney(params.value),
  },
  {
    field: 'vat',
    flex: 1,
    headerName: 'Moms',
  },
  {
    field: 'site',
    flex: 1,
    headerName: 'Sajt',
    valueGetter: params => sites[params.value],
  },
];

export default function ProductListResults({ params }) {
  const { productType, site, betType, onlyPromoted, search } = params;

  return (
    <DataGrid
      columns={columns}
      query={query}
      defaultSort="sku"
      filter={{
        productType,
        site,
        betType,
        search,
        onlyPromoted,
      }}
    />
  );
}

ProductListResults.propTypes = {
  params: PropTypes.shape({
    search: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    betType: PropTypes.string.isRequired,
    onlyPromoted: PropTypes.bool.isRequired,
  }).isRequired,
};
