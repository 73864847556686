import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import { NavLink, useLocation } from 'react-router-dom';
import { transactionStatus } from 'trhub-utils';

import NoData from '~/components/NoData';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { orderPropType } from '~/propTypes';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderTransactions({ order }) {
  const { transactions, paymentMethod, paymentStatus, refundFor } = order;
  const { pathname } = useLocation();
  return (
    <>
      <TablePaper title="Transaktioner">
        {transactions.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Datum</TableCell>
                  <TableCell>Belopp</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Typ</TableCell>
                  <TableCell>Meddelande</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map(
                  transaction =>
                    transaction && (
                      <StyledTableRow key={transaction.id}>
                        <TableCell>
                          <Time
                            date={new Date(transaction.date)}
                            format="yyyy-MM-dd HH:mm"
                          />
                        </TableCell>
                        <TableCell>{formatMoney(transaction.amount)}</TableCell>
                        <TableCell component="th" scope="row">
                          <Chip
                            color="primary"
                            label={transactionStatus[transaction.status]}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{transaction.type}</TableCell>
                        <TableCell>{transaction.message}</TableCell>
                      </StyledTableRow>
                    ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoData text="Den här ordern saknar transaktioner" />
        )}
      </TablePaper>
      {!refundFor &&
        ['card', 'notification', 'autogiro', 'payexInvoice', 'swish'].includes(
          paymentMethod,
        ) &&
        paymentStatus === 'paid' && (
          <Box mt={2}>
            <Button
              component={NavLink}
              to={`${pathname}/refund`}
              variant="contained"
              color="secondary"
            >
              Registrera återbetalning
            </Button>
          </Box>
        )}
    </>
  );
}

OrderTransactions.propTypes = {
  order: orderPropType,
};
