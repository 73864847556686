import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { distributableProducts } from 'trhub-utils';

import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import useAuthUser from '~/hooks/useAuthUser';
import { editionType } from '~/propTypes';

export default function EditionDetail({ editionData }) {
  const { pathname } = useLocation();
  const {
    getEdition: {
      editionLabel,
      sku,
      productionDate,
      lastOrderDate,
      distributionDate,
      deliveryDate,
    },
  } = editionData;

  const { isAdmin } = useAuthUser();

  const tableRows = [
    ['Namn', editionLabel],
    ['Produkt', distributableProducts[sku]],
    [
      'Produktionsdatum',
      productionDate ? (
        <Time key="productionDate" date={new Date(productionDate)} />
      ) : (
        ''
      ),
    ],
    [
      'Sista beställningsdatum',
      lastOrderDate ? (
        <Time key="lastOrderDate" date={new Date(lastOrderDate)} />
      ) : (
        ''
      ),
    ],
    [
      'Uttagsdatum',
      distributionDate ? (
        <Time key="distributionDate" date={new Date(distributionDate)} />
      ) : (
        ''
      ),
    ],
    [
      'Utdelningsdatum',
      <Time key="deliveryDate" date={new Date(deliveryDate)} />,
    ],
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
        <Box mt={2} display="flex">
          <Box
            maxWidth="70%"
            display="grid"
            gridAutoFlow="column"
            gridColumnGap="10px"
          >
            {isAdmin && (
              <Button
                component={NavLink}
                to={`${pathname}/edit`}
                variant="contained"
                color="secondary"
              >
                Redigera
              </Button>
            )}
          </Box>
          <Box ml="auto" maxWidth="30%">
            <Button
              align="right"
              component={NavLink}
              to={`${pathname}/distribution`}
              variant="contained"
              color="secondary"
            >
              Distributionslista
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

EditionDetail.propTypes = {
  editionData: PropTypes.shape({
    getEdition: editionType,
  }).isRequired,
};
