import { useQuery } from '@apollo/client';
import { CircularProgress, Link, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';
import { typesPropTypes } from '~/propTypes';

import query from './_GetIssues.gql';

export default function IssueListResults({ params, options }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination();
  const { severity, category, assignee, status, searchValues: search } = params;
  const { error, data } = useQuery(query, {
    variables: {
      filter: {
        severity,
        category,
        assignee,
        status,
        search,
      },
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getIssues;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = [
    'Avsändare',
    'Allvarsgrad',
    'Kategori',
    'Mottagare',
    'Skapad',
    'Status',
  ];

  return (
    <TableList
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.issues.length > 0 ? (
        res.issues.map(issue => (
          <TableRow hover key={issue.id}>
            <TableCell>
              <Link component={RouterLink} to={`/issues/${issue.id}`}>
                {issue.dispatcher}
              </Link>
            </TableCell>
            <TableCell>
              {issue.severity &&
                options.severities.find(
                  option => option.value === issue.severity,
                ).text}
            </TableCell>
            <TableCell>
              {issue.category &&
                options.categories.find(
                  option => option.value === issue.category,
                ).text}
            </TableCell>
            <TableCell>{issue.assignee}</TableCell>
            <TableCell>
              <Time date={new Date(issue.created)} format="yyyy-MM-dd HH:mm" />
            </TableCell>
            <TableCell>
              {
                options.status.find(option => option.value === issue.status)
                  .text
              }
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={5} align="center">
            <em>Det finns inga ärenden för tillfället.</em>
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

IssueListResults.propTypes = {
  options: PropTypes.shape({
    categories: typesPropTypes,
    severities: typesPropTypes,
    status: typesPropTypes,
  }).isRequired,
  params: PropTypes.shape({
    searchValues: PropTypes.string.isRequired,
    severity: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    assignee: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};
