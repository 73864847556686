import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import {
  organizations,
  paymentIntervals,
  paymentMethods,
  productTypes,
  sites,
} from 'trhub-utils';

import TablePaper from '~/components/TablePaper';
import UploadFile from '~/components/UploadFile';
import useAuthUser from '~/hooks/useAuthUser';
import { productPropType } from '~/propTypes';

export default function ProductDetail({ productData, refetch }) {
  const { pathname } = useLocation();

  const { isAdmin } = useAuthUser();

  const {
    id,
    name,
    type,
    organization,
    sku,
    price,
    vat,
    site,
    products,
    betType,
    allowedPaymentMethods,
    paymentInterval,
    punches,
    costCenter,
    distributable,
    purchasable,
    project,
    pdfUrl,
    pdfReminderUrl,
  } = productData.getProduct;

  const tableRows = [
    ['Namn', name],
    ['Organisation', organizations[organization]],
    ['Produkttyp', productTypes[type]],
    ['SKU', sku],
    ['Pris', formatMoney(price)],
    ['Moms', `${vat}%`],
    ['Sajt', sites[site]],
    [
      'Betalningsmetoder',
      allowedPaymentMethods
        .map(paymentMethod => paymentMethods[paymentMethod])
        .join(', '),
    ],
    [
      'Distribueringsbar (KRAV för Billecta)',
      <FormControlLabel
        key={sku}
        control={<Checkbox checked={distributable ?? false} />}
      />,
    ],
    [
      'Köpbar',
      <FormControlLabel
        key={sku}
        control={<Checkbox checked={purchasable ?? false} />}
      />,
    ],
  ];

  if (type === 'subscription') {
    tableRows.push(['Betalningsinterval', paymentIntervals[paymentInterval]]);

    products.length > 0 &&
      tableRows.push([
        'Produkter',
        products.map(product => product.name).join(', '),
      ]);
  }

  if (type === 'bettip' || type === 'ticket') {
    tableRows.push(['Spelformer', betType.join(', ')]);
    if (type === 'ticket') {
      tableRows.push(['Klipp', punches]);
    }
  }

  if (
    allowedPaymentMethods.some(paymentMethod =>
      ['autogiro', 'notification'].includes(paymentMethod),
    )
  ) {
    tableRows.push(['Kostnadsställe', costCenter], ['Projekt', project]);
  }
  tableRows.push(
    [
      'PDF huvudförnyelse',
      <UploadFile
        key="pdf"
        endpoint={`/api/product_pdf/${id}/main`}
        url={pdfUrl}
        href={pdfUrl}
        refetch={refetch}
        type="pdf"
      />,
    ],
    [
      'PDF påminnelse',
      <UploadFile
        key="pdf"
        endpoint={`/api/product_pdf/${id}/reminder`}
        url={pdfReminderUrl}
        href={pdfReminderUrl}
        refetch={refetch}
        type="pdf"
      />,
    ],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
        {isAdmin && (
          <Box mt={2} display="flex">
            <Box ml={2}>
              <Button
                component={NavLink}
                to={`${pathname}/edit`}
                variant="contained"
                color="secondary"
              >
                Redigera
              </Button>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

ProductDetail.propTypes = {
  productData: PropTypes.shape({
    getProduct: productPropType,
  }).isRequired,
  refetch: PropTypes.func,
};
