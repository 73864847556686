import { Box, Button, LinearProgress, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { issueCategories, issueSeverities, issueStatus } from 'trhub-utils';
import * as Yup from 'yup';

import toOptions from '~/utils/toOptions';

const validationSchema = Yup.object().shape({
  dispatcher: Yup.string().required('En avsändare måste anges.'),
  category: Yup.string().required('En kategori måste anges.'),
  description: Yup.string().required('Beskrivning måste anges.'),
  severity: Yup.string().required('Allvarsgrad måste anges.'),
  status: Yup.string().required('Status måste anges.'),
  assignee: Yup.string().when('status', {
    is: value => value !== 'NEW',
    then: Yup.string().required('En mottagare måste anges.'),
  }),
});

export default function IssueForm({ initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, isSubmitting }) => (
        <Form
          css={({ theme }) => ({
            '> * + *': {
              marginTop: theme.spacing(2),
            },
            '> *': {
              width: '100%',
            },
            '> .MuiFormControl-root': {
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Field
            component={TextField}
            name="dispatcher"
            label="Avsändare"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="category"
            label="Kategori"
            variant="outlined"
            select
          >
            {toOptions(issueCategories).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          <Field
            component={TextField}
            name="description"
            label="Beskrivning"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="severity"
            label="Allvarsgrad"
            variant="outlined"
            select
          >
            {toOptions(issueSeverities).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          <Field
            component={TextField}
            name="status"
            label="Status"
            variant="outlined"
            select
          >
            {toOptions(issueStatus).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          <Field
            component={TextField}
            name="assignee"
            label="Mottagare"
            variant="outlined"
          />

          {isSubmitting && <LinearProgress />}
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Spara
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

IssueForm.propTypes = {
  initialValues: PropTypes.shape({ description: PropTypes.string }),
  totalPercentError: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};
