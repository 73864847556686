import { Link, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import NoData from '~/components/NoData';
import TableList from '~/components/TableList';

export default function UserList({ users, type }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = event => {
    setPage(event);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headers = ['Användar-id'];

  const title = `${
    type === 'blocked'
      ? 'Blockerade användare'
      : type === 'subscriptionExists'
      ? 'Prenumeration finns redan för användare'
      : ''
  }`;

  return (
    <TableList
      title={title}
      page={page + 1}
      data={{
        items: users,
        total: users.length,
      }}
      pageSize={rowsPerPage}
      headers={headers}
      updatePage={handleChangePage}
      updateRowSize={handleChangeRowsPerPage}
    >
      {users
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(user => (
          <TableRow key={user.id}>
            <TableCell>
              <Link component={RouterLink} to={`/users/${user.id}`}>
                {user.name != null && user.name !== '' ? user.name : user.id}
              </Link>
            </TableCell>
          </TableRow>
        ))}

      {users.length === 0 && (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData text="Det finns inga användare" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  type: PropTypes.string,
};
