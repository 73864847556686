import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetDeviatedOrders.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function DeviationsListResults({ params }) {
  const { from, to, source, organization } = params;

  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);
  const { error, data } = useQuery(query, {
    variables: {
      page: page,
      limit: pageSize,
      filter: {
        from,
        to,
        source,
        organization,
      },
    },
  });
  const res = data?.list;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = [
    'Avvikelsedatum',
    'Order',
    'Orderbelopp',
    'Inbetalt',
    'Differens',
    'Kund',
    'Källa',
  ];

  return (
    <>
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="h2">
              Avvikelser (Totalt för perioden: {formatMoney(res.totalAmount)})
            </Typography>
          </CardContent>
          <TableList
            page={page}
            pageSize={pageSize}
            data={res}
            headers={headers}
            updatePage={updatePage}
            updateRowSize={updateRowSize}
          >
            {res?.items.length > 0 ? (
              res.items.map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell className={classes.tableCellDate}>
                    <Time
                      date={
                        new Date(item.payments[item.payments.length - 1].date)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/order/${item.id}`}>
                      {item.label}
                    </Link>
                  </TableCell>
                  <TableCell>{formatMoney(item.totalPrice)}</TableCell>
                  <TableCell>{formatMoney(item.paidAmount)}</TableCell>
                  <TableCell>
                    {(item.paidAmount - item.totalPrice > 0 ? '+' : '') +
                      formatMoney(item.paidAmount - item.totalPrice)}
                  </TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/users/${item.user.id}`}>
                      {item.user.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {item.payments[item.payments.length - 1].source}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  Inga betalningsavvikelser finns för denna filtrering. Prova
                  att ange ett annat datumintervall.
                </TableCell>
              </TableRow>
            )}
          </TableList>
        </Card>
      </Box>
    </>
  );
}

DeviationsListResults.propTypes = {
  params: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    source: PropTypes.string,
    organization: PropTypes.string,
  }),
};
