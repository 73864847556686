import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { format } from 'date-fns';
import { organizations } from 'trhub-utils';

import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import DeviationsListResults from './DeviationsListResult';
import MissingPaymentOrdersListResults from './MissingPaymentOrdersListResult';
import UnhandledPaymentsListResults from './UnhandledPaymentsList';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 180,
    minWidth: 135,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  from: format(new Date(), 'yyyy-MM'),
  to: format(new Date(), 'yyyy-MM'),
  source: '',
  organization: '',
};

export default function DeviationsList() {
  const classes = useStyles();
  const urlParams = new URLSearchParams();

  const { params, state, clear, handleChange } = useQueryParams(initialValues);
  if (state.from) {
    urlParams.set('from', state.from);
    urlParams.set('to', state.to);
    urlParams.set('source', state.source);
    urlParams.set('organization', state.organization);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Avvikelserapport
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            För att se omatchade betalningar, se Billecta ➔ Kundfakturor ➔
            Betalningar ➔ Öppna/ohanterade betalningar.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Avvikelser är inkommande betalningar som skiljer sig med beloppet på
            kopplad order. Orsaken är vanligtvis att kunden har betalat in för
            lite eller för mycket på en order.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Saknade inbetalningar är ordrar som är markerade som betalda, men
            saknar en registrerad inbetalning. Detta kan ske för ordrar som
            betalats med kort där vi inte har mottagit inbetalnings-filen från
            Swedbank Pay ännu.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Omatchade inbetalningar är betalningar från Swedbank Pay som vi inte
            kunnat matcha mot en order.
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Källa"
            variant="outlined"
            select
            value={state.source}
            onChange={e => handleChange('source', e.target.value)}
          >
            <MenuItem value={''}>
              <em>Alla</em>
            </MenuItem>
            <MenuItem value="billecta">Billecta</MenuItem>
            <MenuItem value="swedbankpay">Swedbank Pay</MenuItem>
          </TextField>
          <TextField
            className={classes.textField}
            label="Organisation"
            variant="outlined"
            select
            value={state.organization}
            onChange={e => handleChange('organization', e.target.value)}
          >
            <MenuItem value={''}>
              <em>Alla</em>
            </MenuItem>
            {Object.keys(organizations).map(org => (
              <MenuItem key={org} value={org}>
                {organizations[org]}
              </MenuItem>
            ))}
          </TextField>
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/deviation?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Avvikelserapport
          </Button>
        </Box>
      </Box>
      <Box>
        <DeviationsListResults params={params} />
        <MissingPaymentOrdersListResults params={params} />
        <UnhandledPaymentsListResults params={params} />
      </Box>
    </>
  );
}
